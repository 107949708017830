import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../../composants/image/Image"
import LayoutTutorielEN from "../../../composants/layout/en/LayoutTutorielEN"
import Lien from "../../../composants/navigation/Lien"
import Section from "../../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageYamlPhotographierArcheVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "photographierArcheVoieLacteeEN" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: ["traitementImagesVoieLacteeEN", "hessII360EN", "megalaxyZoomEN"]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteePtGUI"
            "voieLacteeArcheApresRedressement"
            "voieLacteeRedressementdeLarcheDansPhotoshop"
            "voieLacteeArchedeLaVoieLacteeSurUnePlageDuMorbihan"
            "voieLacteeSortiedassemblageDuLogicielPanoramique"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteeArcheImageDuSol"
            "voieLacteeArcheImageAvecDuSolEtDuCiel"
            "voieLacteeArcheImageDuZenith"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitMoyen
    }
  }
`

export default function PhotographMilkyWayArch() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(
    resultatsRequete,
    "pageYamlPhotographierArcheVoieLactee"
  )
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortraitMoyen"
  )

  return (
    <LayoutTutorielEN
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Principles">
        <p>
          The photographs of the Milky Way arch are <strong>panoramic</strong>{" "}
          images of the starry sky. The surface they cover is vast: from the
          south to the north, from the ground at the zenith. Therefore, they are
          composed of <strong>many photos</strong> (sometimes several dozens).
          After assembling, they are processed with an image editing software to
          get the desired result.
        </p>
        <Image
          image={obtenirImage(
            images,
            "voieLacteeArchedeLaVoieLacteeSurUnePlageDuMorbihan"
          )}
          langue={page.langue}
        />
      </Section>
      <Section titre="Equipment">
        <p>
          You must use <strong>a wide angle lens and a panoramic head</strong>,
          indispensable to create panoramics composed of many individual images.
          I use a Bushman Gobi panoramic head that is very light and very
          compact.
        </p>
      </Section>
      <Section titre="When?">
        <p>
          The Milky Way must be <strong>high in the sky</strong> so that it is
          clearly visible on the images. That is to say from May at the end of
          the night until the end of September at the beginning of the night.
        </p>
        <p>
          Note that the Milky Way must not be too high in the sky (between 60°
          and 80°): it will{" "}
          <strong>will facilitate the assembly of the panoramic</strong> (there
          will be less deformations of the arch).
        </p>
      </Section>
      <Section titre="Where?">
        <p>
          You must preferably find a place with no light pollution{" "}
          <strong>on the northern horizon and on the southern horizon</strong>.
          Such places have become too rare in Europe. Mountainous massifs are
          ideal, provided you climb on the heights. Help yourself with light
          pollution maps.
        </p>
      </Section>
      <Section titre="Shooting">
        <p>
          To create the panoramic image, you must take{" "}
          <strong>several rows</strong> of photos (depending of the focal length
          you use) from the ground at the zenith. Start with shots of the
          ground, then ground and sky, then finish by the zenith (some pictures
          will suffice). Do not change{" "}
          <strong>
            neither the focal length nor the settings of your lens
          </strong>
          .
        </p>
        <p>Before starting shooting:</p>
        <ul>
          <li>Check that your tripod is (roughly) at level.</li>
          <li>
            If there are luminous places in the panorama (light pollution),
            check that your settings does not overexpose them.
          </li>
          <li>
            NB: the shooting technique of each photo of the panorama is
            described in the article “
            <Lien codePageCible="photographierLaVoieLacteeEN">
              Photograph the Milky Way
            </Lien>
            ”.
          </li>
        </ul>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "voieLacteeArcheImageDuSol")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(
              images,
              "voieLacteeArcheImageAvecDuSolEtDuCiel"
            )}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "voieLacteeArcheImageDuZenith")}
            langue={page.langue}
          />
        </div>
      </Section>
      <Section titre="Preprocessing in Lightroom">
        <p>
          Process all your photos in <strong>Lightroom</strong> using the same
          settings: on one of your photos, correct the exposure and the optical
          defects (<strong>distortions and especially vignetting</strong>). Then
          synchronize these settings with the other photos of your series (in
          “Library”: “Sync Param” at the bottom right).
        </p>
        <p>
          <strong>Be careful</strong>: do not reduce noise at this step to not
          lose details. You will do it after the final aesthetic processing is
          done.
        </p>
        <p>Finally export your images in TIFF format.</p>
      </Section>
      <Section titre="Panorama stitching">
        <p>
          Merge your panorama with <strong>Ptgui</strong> (or other equivalent
          software like Autopano or Microsoft ICE). If it is not properly
          merged, <strong>you will need to help the</strong> software. Add
          control points manually, or Automatically (“Control Points &gt;
          Generate Control Points”).
        </p>
        <p>
          Once the panoramic assembled, the zenith stars may be crushed by the
          default projection used. <strong>You may have to change it</strong>.
          In the panorama settings, try for example the “Mercator” projection.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteePtGUI")}
          langue={page.langue}
        />
        <p>
          Finally save your panoramic image. If elements do not appear as you
          wish (ex: meteorite or a character), it is possible to export the
          panorama as a PSD file for Photoshop (with one layer for each image).
          You will be able to reveal the desired items using layer masks.
        </p>
      </Section>
      <Section titre="Finish with Photoshop">
        <p>
          We will use Photoshop to straighten our image (if necessary). Open The
          image, then crop it to remove edges that are too distorted.
        </p>
        <Image
          image={obtenirImage(
            images,
            "voieLacteeSortiedassemblageDuLogicielPanoramique"
          )}
          langue={page.langue}
        />
        <p>
          <strong>Straighten</strong> the panoramic image can be a delicate
          step. Go in the “Edit &gt; Muppet transform” (CS5 versions and above).
          Duplicate the background layer (Ctrl + J) on a new layer, and position
          4 points at the 4 corners of the layer. Then pull the 2 points from
          the bottom as shown on the image below. Complete the recovery by
          positioning using other points.
        </p>
        <Image
          image={obtenirImage(
            images,
            "voieLacteeRedressementdeLarcheDansPhotoshop"
          )}
          langue={page.langue}
        />
        <p>
          Crop, and you will get a ready image for final aesthetics processing!
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeArcheApresRedressement")}
          langue={page.langue}
        />
      </Section>
    </LayoutTutorielEN>
  )
}
